import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaInstagram, FaWhatsapp, FaFacebook, FaYoutube, FaLinkedin, FaEnvelope, FaPhone } from "react-icons/fa";
import { Link } from "react-router-dom";
import UniHomeLogo from "./Assets/images/UniHome.png";
import Modal from "./Modal";
import axios from "axios";

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: "", content: "" });
  const [contacts, setContacts] = useState([]);

  const { t } = useTranslation();

  const handleOpenModal = (title, content) => {
    setModalContent({ title, content });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    async function fetchContacts() {
      try {
        const response = await axios.get("https://yousab-tech.com/unihome/public/api/contacts");
        setContacts(response.data.data.contacts);
        console.log(contacts);

      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    }

    fetchContacts();
  }, []);

  const renderIcon = (icon) => {
    switch (icon) {
      case "fab fa-facebook-f":
        return <FaFacebook />;
      case "fab fa-instagram":
        return <FaInstagram />;
      case "fab fa-whatsapp":
        return <FaWhatsapp />;
      case "fab fa-linkedin":
        return <FaLinkedin />;
      case "fas fa-mail-bulk":
        return <FaEnvelope />;
      case "fas fa-phone":
        return <FaPhone />;
      default:
        return null;
    }
  };

  return (
    <>
      <footer className="bg-gray-800 text-white pt-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-between">
            {/* Logo and Social Section */}
            <div className="w-full lg:w-1/4 text-center lg:text-left">
              <Link to="/" className="flex items-center justify-center mb-6">
                <img
                  src={UniHomeLogo}
                  alt="UniHome Logo"
                  className="w-20 h-20 rounded-xl mr-3"
                />
              </Link>
              <p className="text-sm md:text-base leading-6">
                Get the best private lessons online with professional teachers.
                Choose from a variety of live courses and learn from home with ease.
              </p>
            </div>

            {/* Links Section */}
            <div className="w-full sm:w-1/2 lg:w-1/4 text-center">
              <h6 className="text-[#ff5a1f] text-lg font-bold uppercase mb-4">Links</h6>
              <ul className="space-y-3">
                <li>
                  <Link
                    to="#"
                    onClick={() =>
                      handleOpenModal(
                        "FAQ",
                        "This section provides answers to the most common questions about UniHome."
                      )
                    }
                    className="hover:underline"
                  >
                    FAQ
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    onClick={() =>
                      handleOpenModal(
                        "Contact",
                        "For inquiries, contact us at info@unihome.com."
                      )
                    }
                    className="hover:underline"
                  >
                    Contact
                  </Link>
                </li>
                <li>
                  <Link to="/Privacy-Policy" className="hover:underline">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/Developers" className="hover:underline">
                    Developers
                  </Link>
                </li>
              </ul>
            </div>

            {/* Platform Section */}
            <div className="w-full sm:w-1/2 lg:w-1/4 text-center">
              <h6 className="text-[#ff5a1f] text-lg font-bold uppercase mb-4">Platform</h6>
              <ul className="space-y-3">
                <li>
                  <Link
                    to="#"
                    onClick={() =>
                      handleOpenModal(
                        "About Us",
                        "Learn more about our journey and the team behind UniHome."
                      )
                    }
                    className="hover:underline"
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    onClick={() =>
                      handleOpenModal(
                        "Services",
                        "UniHome offers services designed to support students."
                      )
                    }
                    className="hover:underline"
                  >
                    Services
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    onClick={() =>
                      handleOpenModal(
                        "Reviews",
                        "See what our users have to say about UniHome."
                      )
                    }
                    className="hover:underline"
                  >
                    Reviews
                  </Link>
                </li>
              </ul>
            </div>

            {/* Contact Section */}
            <div className="w-full lg:w-1/4 text-center">
              <h6 className="text-[#ff5a1f] text-xl font-bold uppercase mb-6 tracking-wide">
                {t("contactUs")}
              </h6>
              <ul className="space-y-4">
                {contacts.map((contact) => (
                  <li key={contact.id} className="flex items-center justify-center space-x-3">
                    <a
                      href={
                        contact.type === "phone"
                          ? `tel:${contact.contact}`
                          : contact.type === "whatsapp"
                            ? `https://wa.me/${contact.contact.replace(/[^0-9]/g, "")}`
                            : contact.type === "email"
                              ? `mailto:${contact.contact}`
                              : contact.contact
                      }
                      target={contact.type === "social" || contact.type === "whatsapp" ? "_blank" : "_self"}
                      rel="noopener noreferrer"
                      className="flex items-center gap-2 text-gray-700 dark:text-gray-200"
                    >
                      <span className="text-xl text-[#ff5a1f]">{renderIcon(contact.icon)}</span>
                      <span className="font-medium text-md hover:text-blue-600 transition-colors duration-300">
                        {contact.contact}
                      </span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>

          </div>
        </div>
        <div className="bg-gray-900 text-center py-4 mt-8">
          <p className="text-sm font-bold">
            2024 © UniHome. All Rights Reserved.
          </p>
        </div>

        {/* Modal */}
        <Modal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          title={modalContent.title}
          content={modalContent.content}
        />
      </footer>
    </>
  );
};

export default Footer;
